import AuthenticatedRoute from "RouteGuards/AuthenticatedRoute";
import UnauthenticatedRoute from "./RouteGuards/UnauthenticatedRoute";
import ConsentsRoute from "RouteGuards/ConsentsRoute";
import AccountFoundLogin from "components/account/CardReplacement/AccountFoundLogin";
import AdditionalInformation from "components/account/CardReplacement/AdditionalInformation";
import CardReplacementLogin from "components/account/CardReplacement/CardReplacementLogin";
import VerificationCodeLogin from "components/account/CardReplacement/VerificationCodeLogin";
import CountrySelection from "components/account/CountrySelection";
import Login from "components/account/Login";
import AddYourDataPasswordRecovery from "components/account/PasswordRecovery/AddYourDataPasswordRecovery";
import EmailExpiredPasswordRecovery from "components/account/PasswordRecovery/EmailExpiredPasswordRecovery";
import EmailVerifiedPasswordRecovery from "components/account/PasswordRecovery/EmailVerifiedPasswordRecovery";
import EnterEmailPhonePasswordRecovery from "components/account/PasswordRecovery/EnterEmailPhonePasswordRecovery";
import VerificationCodePasswordRecovery from "components/account/PasswordRecovery/VerificationCodePasswordRecovery";
import B2bData from "components/b2bregistration/B2bData";
import CongratulationsB2b from "components/b2bregistration/CongratulationsB2b";
import CreateNewB2bAccount from "components/b2bregistration/CreateNewB2bAccount";
import ConsentsAcceptance from "components/components/ConsentsAcceptance";
import VerificationEmailSent from "components/components/VerificationEmailSent";
import Error from "components/identity-server/Error";
import BusinessCommunication from "components/profile/BusinessCommunication";
import BusinessProfileInformation from "components/profile/BusinessProfileInformation";
import AccountFound from "components/profile/CardReplacement/AccountFound";
import CardReplacement from "components/profile/CardReplacement/CardReplacement";
import CardTypeSelection from "components/profile/CardReplacement/CardTypeSelection";
import VerificationCode from "components/profile/CardReplacement/VerificationCode";
import Clubs from "components/profile/Clubs";
import KidsClub from "components/profile/Clubs/KidsClub";
import PetsClub from "components/profile/Clubs/PetsClub";
import SeniorsClub from "components/profile/Clubs/SeniorsClub";
import WineClub from "components/profile/Clubs/WineClub";
import Communication from "components/profile/Communication";
import ECommerce from "components/profile/ECommerce";
import EmailVerifiedOnEmailChange from "components/profile/EmailVerifiedOnEmailChange";
import EmailVerifiedProfile from "components/profile/EmailVerifiedProfile";
import FavouriteStore from "components/profile/FavouriteStore";
import MyRimiGroup from "components/profile/MyRimiGroup";
import ProfileInformation from "components/profile/ProfileInformation";
import ExistingCard from "components/registration/ExistingCard";
import EmailVerifiedHasAccount from "components/registration/HasAccount/EmailVerifiedHasAccount";
import EnterEmail from "components/registration/HasAccount/EnterEmail";
import EnterEmailWithHint from "components/registration/HasAccount/EnterEmailWithHint";
import EnterExistingCard from "components/registration/HasAccount/EnterExistingCard";
import Congratulations from "components/registration/NoAccount/Congratulations";
import CreateNewMyRimiAccount from "components/registration/NoAccount/CreateNewMyRimiAccount";
import EmailExpired from "components/registration/NoAccount/EmailExpired";
import YourData from "components/registration/NoAccount/YourData";
import urlTools from "components/tools/urlTools";
import Unsubscribe from "components/unsubscribe/Unsubscribe";
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom/cjs/react-router-dom";
import PropTypes from "prop-types";

class Routes extends Component {
	getRedirectAfterEmailVerifiedLink() {
		if (this.props.initialData.redirectAfterEmailChange) {
			return '/profile/EmailVerifiedOnEmailChange/';
		}
		else if (this.props.initialData.passwordCreationForm || this.props.initialData.passwordResetForm) {
			return '/passwordrecovery/EmailVerified/';
		}
		else if (this.props.initialData.verifyAndLoginToProfile) {
			return '/profile/EmailVerified/';
		}
	}

	getRedirectAfterEmailVerifiedLinkComponent() {
		if (this.props.initialData.redirectAfterEmailChange) {
			return <EmailVerifiedOnEmailChange
				initialData={this.props.initialData}
			/>
		}
		else if (this.props.initialData.passwordCreationForm || this.props.initialData.passwordResetForm) {
			return <EmailVerifiedPasswordRecovery
				initialData={this.props.initialData}
				showEmailVerify={this.props.initialData.passwordCreationForm}
			/>
		}
		else if (this.props.initialData.verifyAndLoginToProfile) {
			return <EmailVerifiedProfile
				initialData={this.props.initialData}
			/>
		}
	}

	render() {
		return (
			<Switch>
				<Route
					path="/passwordrecovery/EmailVerified/"
					component={() => (
						<EmailVerifiedPasswordRecovery
							initialData={this.props.initialData}
						/>
					)} />
				<Route
					path="/passwordrecovery/EmailExpired/"
					component={() => (
						<EmailExpiredPasswordRecovery
							initialData={this.props.initialData}
						/>
					)} />
				<UnauthenticatedRoute
					path="/passwordrecovery/RemindEmail/"
					initialData={this.props.initialData}
					component={(
						<EnterExistingCard
							initialData={this.props.initialData}
							remindEmailFlow={true}
							{...this.props}
						/>
					)} />
				<UnauthenticatedRoute
					path="/registration/HasAccount/EmailExpired"
					initialData={this.props.initialData}
					component={(
						<EmailExpired
							initialData={this.props.initialData}
							titleTranslation={'expired_verification_link_after_eml_change_title'}
							infoTranslation={'expired_verification_link_after_eml_change_info'}
							buttonTranslation={'expired_verification_link_after_eml_change_btn'}
						/>
					)} />
				<UnauthenticatedRoute
					path="/registration/NoAccount/EmailExpired"
					initialData={this.props.initialData}
					component={(
						<EmailExpired
							initialData={this.props.initialData}
							titleTranslation={'email_verification_link_has_expired_page_title'}
							infoTranslation={'email_verification_link_has_expired_page_info'}
							buttonTranslation={'email_verification_link_has_expired_page_return_btn'}
						/>
					)} />
				<Route
					path={this.getRedirectAfterEmailVerifiedLink(), "/SSO_WEB/RimiSSO/SSOAccount/verification/"}
					component={() => this.getRedirectAfterEmailVerifiedLinkComponent()} />
				<Route
					path={this.getRedirectAfterEmailVerifiedLink(), "/verification-callback"}
					component={() => this.getRedirectAfterEmailVerifiedLinkComponent()} />
				<UnauthenticatedRoute
					path="/VerificationEmailSent"
					initialData={this.props.initialData}
					component={(
						<VerificationEmailSent
							initialData={this.props.initialData}
							{...this.props}
						/>
					)} />
				<UnauthenticatedRoute
					path="/b2bregistration/B2bData"
					initialData={this.props.initialData}
					component={(
						<B2bData
							initialData={this.props.initialData}
						/>
					)} />
				<UnauthenticatedRoute
					path="/b2bregistration/CongratulationsB2b"
					initialData={this.props.initialData}
					component={(
						<CongratulationsB2b
							initialData={this.props.initialData}
						/>
					)} />
				<UnauthenticatedRoute
					path="/b2bregistration/CreateNewB2bAccount"
					initialData={this.props.initialData}
					component={(
						<CreateNewB2bAccount
							initialData={this.props.initialData}
						/>
					)} />
				<Route
					path="/registration/HasAccount/EmailVerified"
					component={() => (
						<EmailVerifiedHasAccount
							initialData={this.props.initialData}
						/>
					)} />
				<UnauthenticatedRoute
					path="/registration/HasAccount/EnterEmail"
					initialData={this.props.initialData}
					component={(
						<EnterEmail
							initialData={this.props.initialData}
							{...this.props}
						/>
					)} />
				<Route
					path="/registration/HasAccount/Congratulations"
					component={() => (
						<Congratulations
							initialData={this.props.initialData}
						/>
					)} />
				<UnauthenticatedRoute
					path="/registration/HasAccount/YourData"
					initialData={this.props.initialData}
					component={(
						<YourData
							initialData={this.props.initialData}
						/>
					)} />
				<UnauthenticatedRoute
					path="/registration/HasAccount/EnterExistingCard"
					initialData={this.props.initialData}
					component={(
						<EnterExistingCard
							initialData={this.props.initialData}
						/>
					)} />
				<UnauthenticatedRoute
					path="/registration/HasAccount/EnterEmailWithHint"
					initialData={this.props.initialData}
					component={(
						<EnterEmailWithHint
							initialData={this.props.initialData}
							{...this.props}
						/>
					)} />
				<UnauthenticatedRoute
					path="/registration/NoAccount/CreateNewMyRimiAccount"
					initialData={this.props.initialData}
					component={(
						<CreateNewMyRimiAccount
							initialData={this.props.initialData}
							{...this.props}
						/>
					)} />
				<UnauthenticatedRoute
					path="/registration/ExistingCard"
					initialData={this.props.initialData}
					component={(
						<ExistingCard
							initialData={this.props.initialData}
						/>
					)} />
				<UnauthenticatedRoute
					path={["/passwordrecovery/enteremail/"]}
					initialData={this.props.initialData}
					component={(
						<EnterEmailPhonePasswordRecovery
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
							{...this.props}
						/>
					)} />
				<UnauthenticatedRoute
					path={["/passwordrecovery/addyourdata/"]}
					initialData={this.props.initialData}
					component={(
						<AddYourDataPasswordRecovery
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
							{...this.props}
						/>
					)} />
				<UnauthenticatedRoute
					path={["/passwordrecovery/verificationcode/"]}
					initialData={this.props.initialData}
					component={(
						<VerificationCodePasswordRecovery
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
							{...this.props}
						/>
					)} />
				<AuthenticatedRoute
					path="/kidsclub"
					initialData={this.props.initialData}
					component={(
						<KidsClub
							initialData={this.props.initialData}
						/>
					)} />
				<AuthenticatedRoute
					path="/petsclub"
					initialData={this.props.initialData}
					component={(
						<PetsClub
							initialData={this.props.initialData}
						/>
					)} />
				<AuthenticatedRoute
					path="/wineclub"
					initialData={this.props.initialData}
					component={(
						<WineClub />
					)} />
				<AuthenticatedRoute
					path="/seniorsclub"
					initialData={this.props.initialData}
					component={(
						<SeniorsClub />
					)} />
				<UnauthenticatedRoute
					path={["/login"]}
					initialData={this.props.initialData}
					component={(
						<Login
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<UnauthenticatedRoute
					path={["/CountrySelection"]}
					initialData={this.props.initialData}
					component={(
						<CountrySelection
							initialData={this.props.initialData}
							{...this.props}
						/>
					)} />
				<ConsentsRoute
					path="/ConsentsAcceptance"
					initialData={this.props.initialData}
					component={(
						<ConsentsAcceptance
							oldConsents={this.props.initialData.oldConsents}
							acceptanceRoute="api/Consents"
							hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<ConsentsRoute
					path="/ConsentsAcceptanceB2b"
					initialData={this.props.initialData}
					component={(
						<ConsentsAcceptance
							oldConsents={this.props.initialData.oldConsents}
							acceptanceRoute="api/Consents/B2B"
							hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<AuthenticatedRoute
					path="/ConsentsReacceptance"
					initialData={this.props.initialData}
					component={(
						<ConsentsAcceptance
							oldConsents={this.props.initialData.oldConsents}
							acceptanceRoute="api/Consents/Reaccept"
							hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<AuthenticatedRoute
					path="/ConsentsReacceptanceB2b"
					initialData={this.props.initialData}
					component={(
						<ConsentsAcceptance
							oldConsents={this.props.initialData.oldConsents}
							acceptanceRoute="api/Consents/B2B/Reaccept"
							hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<AuthenticatedRoute
					path="/accountFound/"
					initialData={this.props.initialData}
					component={(
						<AccountFound
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
							{...this.props}
						/>
					)} />
				<Route
					path="/CardTypeSelection/"
					component={() => (
						<CardTypeSelection
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<UnauthenticatedRoute
					path="/cardReplacementLogin/"
					initialData={this.props.initialData}
					component={(
						<CardReplacementLogin
							initialData={this.props.initialData}

						//hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<Route
					path="/cardReplacement/"
					component={() => (
						<CardReplacement
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<Route
					path="/verificationCode/"
					component={() => (
						<VerificationCode
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
							{...this.props}
						/>
					)} />
				<UnauthenticatedRoute
					path="/VerificationCodeLogin/"
					initialData={this.props.initialData}
					component={(
						<VerificationCodeLogin
							initialData={this.props.initialData}
							{...this.props}
						/>
					)} />
				<UnauthenticatedRoute
					path="/AdditionalInformation/"
					initialData={this.props.initialData}
					component={(
						<AdditionalInformation
							initialData={this.props.initialData}
							{...this.props}
						/>
					)} />

				<UnauthenticatedRoute
					path="/AccountFoundLogin/"
					initialData={this.props.initialData}
					component={(
						<AccountFoundLogin
							initialData={this.props.initialData}
						/>
					)} />

				<AuthenticatedRoute
					path="/ProfileInformation"
					initialData={this.props.initialData}
					component={(
						this.props.initialData.businessLogin ? (<BusinessProfileInformation
							initialData={this.props.initialData}
							updateGreetingText={this.props.updateGreetingText}
							hideNavBar={this.props.hideNavBar}

						/>) : (
							<ProfileInformation
								initialData={this.props.initialData}
								updateGreetingText={this.props.updateGreetingText}
								hideNavBar={this.props.hideNavBar}

							/>)
					)} />
				<AuthenticatedRoute
					path="/myrimioffers"
					initialData={this.props.initialData}
					component={(
						<FavouriteStore
							initialData={this.props.initialData}
						/>
					)} />
				<AuthenticatedRoute
					path="/MyRimiGroup"
					initialData={this.props.initialData}
					component={(
						<MyRimiGroup
							initialData={this.props.initialData}
						/>
					)} />
				<AuthenticatedRoute
					path="/clubs"
					initialData={this.props.initialData}
					component={(
						<Clubs
							initialData={this.props.initialData}
						/>
					)} />
				<AuthenticatedRoute
					path="/communication"
					initialData={this.props.initialData}
					component={(
						this.props.initialData.businessLogin ? (<BusinessCommunication />) : (
							<Communication
								initialData={this.props.initialData}
							/>)
					)} />
				<AuthenticatedRoute
					path="/ECommerce"
					initialData={this.props.initialData}
					component={(
						<ECommerce
							initialData={this.props.initialData}
						/>
					)} />
				<Route
					path="/unsubscribe-callback"
					component={() => (
						<Unsubscribe
							initialData={this.props.initialData}
							hideNavBar={this.props.hideNavBar}
						/>
					)} />
				<Route
					path="/error"
					component={() => <Error />}
				/>

				{/* leave this last - handles any unmatched routes */}
				<Route path="/" component={() => urlTools.getDefaultRedirect(this.props.initialData)} />
			</Switch>
		)
	};
}
export default withRouter(Routes);

Routes.propTypes = {
	initialData: PropTypes.object.isRequired,
	hideNavBar: PropTypes.func.isRequired,
	updateGreetingText: PropTypes.func.isRequired
};