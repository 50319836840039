import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import InputText from "../../components/InputText.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import Footer from "../../components/Footer.jsx";
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import { resolveVerificationCodeInput } from '../../functions/VerificationCodeResolver.jsx';
import { TemporarySuccessDisplay } from '../../components/SuccessDisplay/TemporarySuccessDisplay.jsx';
import urlTools from "../../tools/urlTools.jsx";

class VerificationCodePasswordRecovery extends Component {

	state = {
		activationCode: { code1: "0", code2: "0", code3: "0", code4: "0" },
		errors: [],
		confirmed: false,
		confirmText: "password_recovery_page_reset_pwd_btn",
		showFormAfterError: false,
		email: null,
		isResendSuccessDisplayed: false
	};

	componentDidMount() {
		if (!!this.props.location && !!this.props.location.state && !!this.props.location.state.email) {
			this.setState({ email: this.props.location.state.email });
		}
	}

	onChange = e => {

		const { activationCode } = this.state;
		activationCode[e.prop] = resolveVerificationCodeInput(e);

		this.setState({ activationCode, errors: {} });
	};

	confirm = async e => {

		const { activationCode, errors } = this.state;
		e.preventDefault();
		if (activationCode.code1.length < 1 || activationCode.code2.length < 1 || activationCode.code3.length < 1 || activationCode.code4.length < 1) {

			errors['activation'] = ["activation_code_page_incorrect_code_error"];
			this.setState({ errors });
		}
		else {
			const result = (await fetcherApi.post(urlTools.constructRoute("api/Password/validateVerificationCode"), {
				code1: activationCode.code1,
				code2: activationCode.code2,
				code3: activationCode.code3,
				code4: activationCode.code4
			})).response;

			if (result.data.success) {
				this.setState({ confirmed: true })
			}
			else {
				errors['activation'] = ["activation_code_page_incorrect_code_error"]
				this.setState({ errors, showFormAfterError: true, confirmed: false, confirmText: "try_again_button_label", activationCode: { code1: "0", code2: "0", code3: "0", code4: "0" } });
			}
		}
	};

	getRedirectionLink() {
		const { email } = this.state;
		if (email === null || email === '') {
			return <Redirect to={{
				pathname: '/registration/HasAccount/EnterEmail',
				state: {
					fromVerificationCode: true,
					isBusiness: this.props.location.state.isBusiness
				}
			}} />;
		}
		else {
			return <Redirect to={{
				pathname: '/passwordrecovery/addyourdata',
			}} />;
		}
	}

	hasActivationError() {
		return (this.state.errors['activation'] !== undefined && this.state.errors['activation'].length > 0);
	}

	resendCode = async e => {
		const { errors } = this.state;
		e.preventDefault();

		const result = (await fetcherApi.post(urlTools.constructRoute("api/Password/resendVerificationCode"))).response;

		if (!result.data.success) {
			if (result.data.error === "verification_resend_limit_reached") {
				errors['activation'] = ['password_recovery_page_email_limit_error'];
			} else {
				errors['activation'] = ["unexpected_error"];
			}

			this.setState({ errors });
		}
		else {
			this.setState({ isResendSuccessDisplayed: true });
			this.setState({ errors: {} });
		}
	};

	onResendSuccessDisplayExpiration = () => {
		this.setState({ isResendSuccessDisplayed: false });
	};

	getStepBackButton() {
		return this.state.showFormAfterError ?
			<StepBackHeader
				pathName={'/passwordrecovery/enteremail'}
				buttonText={'step_back_button_label'}
			/>
			:
			<StepBackHeader
				pathName={'/login'}
				buttonText={'login_button_label'}
				iconBeforeText={<svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#a12971" strokeWidth="2" transform="translate(5 4)"><path d="m3.8 5.6v-5.6h7.7c1.2 0 2.1.9 2.1 2.1v11.8c0 1.2-.9 2.1-2.1 2.1h-7.7v-5.6" /><path d="m0 8h9" /><path d="m6.1 10.9 2.9-2.9-2.9-3" /></g></svg>}
			/>
	}

	render() {

		const { activationCode, confirmed, errors, confirmText } = this.state;

		return (
			<React.Fragment>
				<div className="container">
					<div className="card">
						<AutoHideableHeader>
							<RimiLogoHeaderButton initialData={this.props.initialData} />
							{this.getStepBackButton()}
						</AutoHideableHeader>
						<div className="card-content no-padding-bottom">
							<h1>
								<Trans i18nKey={this.props.location.state.isTargetEmail ? "activation_code_email_page_title" : "activation_code_page_title"}>
									Enter verification <b>code sent by SMS</b>
								</Trans>
							</h1>

							<div className="form-group">
								<div className="form-row">
									<div className={this.hasActivationError() ? "form-field  -has-error" : "form-field"}>
										<label htmlFor="activation_code" className="form-field__label">
											<TranslationText text='activation_code_page_code_field_title' />
										</label>
										<div className="verification-code">

											<InputText
												onChange={this.onChange}
												prop="code1"
												name="field-1"
												className="form-field__input"
												type="tel" min="0"
												max="9"
												value={activationCode.code1}
												isSelectedOnFocus={true}
											/>

											<InputText
												onChange={this.onChange}
												prop="code2"
												name="field-2"
												className="form-field__input"
												type="tel" min="0"
												max="9"
												value={activationCode.code2}
												isSelectedOnFocus={true}
											/>
											<InputText
												onChange={this.onChange}
												prop="code3"
												name="field-3"
												className="form-field__input"
												type="tel" min="0"
												max="9"
												value={activationCode.code3}
												isSelectedOnFocus={true}
											/>
											<InputText
												onChange={this.onChange}
												prop="code4"
												name="field-4"
												className="form-field__input"
												type="tel" min="0"
												max="9"
												value={activationCode.code4}
												isSelectedOnFocus={true}
											/>
										</div>
										<div className="message-container">
											<TemporarySuccessDisplay
												isDisplayed={this.state.isResendSuccessDisplayed}
												onExpiration={this.onResendSuccessDisplayExpiration}
												message="verification_code_resend_success_message"
											/>
											<div className="form-row">
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.activation} />
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="form-button full-width">

								{!confirmed ? (
									<button
										type="submit"
										onClick={this.confirm}
										className="button"
									>
										<TranslationText text={confirmText} />
									</button>
								) :
									this.getRedirectionLink()
								}
							</div>

							{this.state.showFormAfterError ?
								(<React.Fragment>
									<div className="form-button full-width">
										<Link className="button -secondary" to={{ pathname: '/login' }}>
											<TranslationText text='return_to_login_page' />
										</Link>
									</div>

									<div className="info-text">
										<p>
											<TranslationText text='verification_code_password_recovery_email_sent_info' />
										</p>
										<p>
											<Trans i18nKey='card_replacement_activation_code_page_try_again'
												components={{ url: <a onClick={this.resendCode} href="#">Try again</a> }}>
												Didn’t receive SMS? <url />
											</Trans>
										</p>
									</div>
								</React.Fragment>
								) :
								(
									<React.Fragment>
										<div className="form-button full-width">
											<button onClick={this.resendCode} className="button -secondary">&nbsp;&nbsp;<TranslationText text='activation_code_page_resend_btn' /></button>
										</div>

										<hr className="spacer" />
									</React.Fragment>
								)

							}


						</div>
						<footer className="card-footer no-border">
							<div className="footer-text">
								<ConsentLinks />
							</div>
						</footer>
					</div>
				</div>
				<Footer countrySelectionEnabled={false} />
			</React.Fragment>
		);
	}
}

export default withTranslation()(VerificationCodePasswordRecovery);


