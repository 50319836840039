import React, { Component } from "react";
import Footer from "../../components/Footer.jsx";
import InputText from "../../components/InputText.jsx";
import fetcherApi from '../../components/FetcherApi.jsx';
import ConsentLinks from "../../components/Consents/ConsentLinks.jsx";
import TranslationText from "../../components/Translation/TranslationText.jsx";
import { Trans, withTranslation } from 'react-i18next';
import RimiLogoHeaderButton from "../../components/HeaderComponents/RimiLogoHeaderButton.jsx";
import AutoHideableHeader from "../../components/HeaderComponents/AutoHideableHeader.jsx";
import StepBackHeader from "../../components/HeaderComponents/StepBackHeader.jsx";
import validator from "../../components/FieldValidator.jsx";
import urlTools from "../../tools/urlTools.jsx";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import DeviceService from "components/services/DeviceService.js";
import EmailAndPhoneValidator from "components/components/EmailAndPhoneValidator.jsx";
import { RECAPTCHA_ERROR } from "components/constants/errorCodes.jsx";

class EnterEmailWithHint extends Component {

	state = {
		email: "",
		errorText: "",
		emailHint: "",
		beginRecaptchaValidation: undefined
	};

	async componentDidMount() {
		const cardNumber = this.props.location.state.cardNumber;
		const cardPrefix = this.props.location.state.cardPrefix;
		const result = (await fetcherApi.post(
			urlTools.constructRoute("api/Password/getEmailHint"),
			{ cardNumber, cardPrefix }
		)).response;

		this.setState({ emailHint: result.data.resultObject });
	}

	onChange = e => {
		let { email } = this.state;
		email = validator.blockSpaceSymbol(e.value);

		this.setState({ email, errors: {} });
	};

	beginRecaptchaValidation = () => {
		this.setState({ beginRecaptchaValidation: !this.state.beginRecaptchaValidation });
	}

	completeRecaptchaValidation = (recaptchaToken) => {
		this.confirm(recaptchaToken);
	}

	confirm = async (recaptchaToken) => {
		const email = this.state.email;
		if (!EmailAndPhoneValidator.IsEmailStructureValid(email)) {
			this.setState({ errorText: "empty_email_error_message" });
		}

		const isDesktop = DeviceService.isDesktop();
		const url = urlTools.constructRoute(`api/Password/SendToEmailOrPhone?isMobile=${!isDesktop}`);
		const requestSettings = { recaptchaToken };
		const payload = { email };

		const combinedResponse = (await fetcherApi.post(url, payload, requestSettings)).response.data;
		if (combinedResponse.success) {
			const isTargetEmail = combinedResponse.resultObject.isTargetEmail;
			this.props.history.push("/PasswordRecovery/VerificationCode", {
				email: combinedResponse.resultObject.email,
				isBusiness: this.props.location.state.isBusiness,
				isTargetEmail
			});
		}

		if (combinedResponse.error === 'isNotEmailOrPhone') {
			this.setState({ errorText: "password_recovery_is_not_email_or_phone" });
		} else if (combinedResponse.error === 'accountTypeMismatch') {
			this.setState({ errorText: "password_recovery_account_type_mismatch" });
		} else if (combinedResponse.error === 'rate_limit_reached' || combinedResponse.error === "verification_resend_limit_reached") {
			this.setState({ errorText: "password_recovery_page_email_limit_error" });
		} else if (combinedResponse.error === RECAPTCHA_ERROR) {
			this.setState({ errorText: RECAPTCHA_ERROR });
		} else if (!combinedResponse.resultObject.authorized) {
			this.setState({ errorText: "account_found_but_no_email_page_incorrect_email_error" });
		} else {
			this.setState({ errorText: "password_recovery_failed_to_send" });
		}
	};

	getStepBackButton() {
		return <StepBackHeader
			pathName={'/passwordrecovery/RemindEmail/'}
			buttonText={'step_back_button_label'} />
	}

	render() {
		return (<React.Fragment>
			<div className="container">
				<div className="card">
					<AutoHideableHeader>
						<RimiLogoHeaderButton initialData={this.props.initialData} />
						{this.getStepBackButton()}
					</AutoHideableHeader>
					<div className={this.state.errorText ? ("card-content") : ("card-content no-padding-bottom")}>
						<h1>
							<Trans i18nKey='email_hint_page_title'>
								Password <b>reset</b>
							</Trans>
						</h1>
						<p>
							<TranslationText text='email_hint_page_hint_msg' /> ({this.state.emailHint})
						</p>
						<div className="form-group">
							<div className="form-row">
								<div className={!this.state.errorText ? "form-field" : "form-field -has-error"}>
									<label htmlFor="email" className="form-field__label"><TranslationText text='email_field_label' /></label>
									<InputText
										onChange={this.onChange}
										prop="email"
										value={this.state.email}
										className="form-field__input"
										placeholder="example@gmail.com"
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="sublabel error-msg show">
									<span><TranslationText text={this.state.errorText} /></span>
								</div>
							</div>
						</div>
						{
							this.state.beginRecaptchaValidation !== undefined
								? <GoogleReCaptcha onVerify={this.completeRecaptchaValidation} refreshReCaptcha={this.state.beginRecaptchaValidation} />
								: null
						}
						<div className="form-button full-width">
							<span className="button" onClick={this.beginRecaptchaValidation}>
								<TranslationText text={!this.state.errorText ? "send_verification_email_button_label" : "try_again_button_label"} />
							</span>
						</div>
					</div>
					<footer className="card-footer no-border">
						<div className="footer-text">
							<ConsentLinks />
						</div>
					</footer>
				</div>
			</div>
			<Footer countrySelectionEnabled={false} />
		</React.Fragment>)

	}
}

export default withTranslation()(EnterEmailWithHint);